<template>
    <div class="flex w-72 max-w-full flex-col" ref="">
        <div class="border-b border-gray-300">
            <div
                class="flex h-16 items-center justify-between gap-2 px-6 text-gray-700"
            >
                <slot name="title">
                    <h5 class="text-lg font-medium">
                        {{ $t('accountantProjects.clientTasks') }}
                    </h5>
                </slot>

                <base-icon
                    class="cursor-pointer"
                    name="line-icons:general:x-close"
                    variant="inherit"
                    @click="emit('on-close-client-task')"
                />
            </div>
        </div>

        <div class="flex items-center border-b border-gray-200 px-6 py-4">
            <div
                class="flex cursor-pointer items-center justify-center gap-2 text-sm font-medium text-primary-700"
                @click="onShowAddSubTask"
            >
                <base-icon
                    name="line-icons:general:plus-circle"
                    variant="inherit"
                />
                {{ $t('accountantProjects.addTask') }}
            </div>
        </div>

        <div class="flex h-full flex-col gap-2.5 overflow-y-auto px-6 py-3.5">
            <form-checkbox
                label-class="py-2 px-2.5"
                v-for="subTask in subTasks"
                :key="subTask.id"
                :model-value="subTask.is_done"
                :disabled="state.loadingStatusSubTasks.includes(subTask.id)"
                @change="onToggleStatusSubtask(subTask)"
            >
                <template #text>
                    <div
                        class="absolute bottom-0 left-0 right-0 top-0 rounded-md border border-gray-300 peer-checked:bg-gray-50"
                    />
                    <span
                        class="z-10 flex flex-col overflow-hidden font-normal peer-checked:line-through"
                    >
                        <span class="text-dark truncate text-sm">
                            {{ subTask.name }}
                        </span>
                    </span>
                </template>
            </form-checkbox>

            <div v-if="state.showAddSubTask" class="flex gap-1" ref="formInput">
                <form-text-input
                    v-model="state.subTaskName"
                    :error-message="state.subTaskNameError"
                    :hide-message="true"
                    :placeholder="$t('accountantProjects.whatNeedToBeDone')"
                    size="sm"
                    :disabled="loadingSaveSubTask"
                    @keyup.enter="onSaveSubTask"
                />
            </div>
        </div>
    </div>
</template>
<script setup>
import { useEventListener } from '@vueuse/core'
import { useClientTask } from '@tenant/modules/accountant/practise-projects/composables/use-client-tasks'

const formInput = ref(null)

const emit = defineEmits(['on-close-client-task'])

const props = defineProps({
    project: {
        type: Object,
        default: null,
    },
    clientTask: {
        type: Object,
        required: true,
    },
})

const { errorNotify } = useNotification()
const { t } = useI18n()

const subTasks = computed(() => props.clientTask.sub_tasks)

const { upsertSubTask, changeStatusSubTask } = useClientTask(props.project?.id)
const { execute: saveSubTask, loading: loadingSaveSubTask } = useApi(
    '/api/accountant/projects/client-tasks/{id}/sub-tasks',
    'POST'
)

useEventListener(formInput, 'keydown', (e) => {
    if (e.key !== 'Escape') {
        return
    }

    state.showAddSubTask = false
})

const state = reactive({
    subTaskName: null,
    showAddSubTask: false,
    loadingStatusSubTasks: [],
})

const onShowAddSubTask = () => {
    state.showAddSubTask = true
}

const onToggleAddSubTask = () => {
    state.showAddSubTask = !state.showAddSubTask
}

const onSaveSubTask = () => {
    saveSubTask({
        data: {
            name: state.subTaskName,
        },
        params: { id: props.clientTask.id },
    })
        .then((subTask) => {
            upsertSubTask(subTask)
            state.subTaskName = null
            onToggleAddSubTask()
        })
        .catch(({ errorMessage }) => {
            errorNotify({
                title: t('general.error'),
                text: errorMessage,
            })
            state.subTaskNameError = errorMessage
        })
}

const onToggleStatusSubtask = (subTask) => {
    state.loadingStatusSubTasks = [...state.loadingStatusSubTasks, subTask.id]

    changeStatusSubTask(props.clientTask.id, subTask.id).finally(() => {
        state.loadingStatusSubTasks = state.loadingStatusSubTasks.filter(
            (i) => i !== subTask.id
        )
    })
}
</script>
